html.dark {
  background-color: rgb(15, 23, 42);
}

.powerup-placeholder {
  background: url('../../assets/Booster-Placement-box.png');
  height: 52px;
  padding: 0 25px;
  position: relative;
  background-size: 100% 100%;
}

.powerup-placeholder-head {
  width: 20px;
  background: url('../../assets/ic-booster-head.png');
  background-size: 100% 100%;
  margin-right: -2px;
}

.powerup-placeholder-tail {
  width: 20px;
  background: url('../../assets/ic-booster-head.png');
  background-size: 100% 100%;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipV;
  -ms-filter: 'FlipV';
  margin-left: -2px;
}

.powerup-disabled {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
