@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@600;700;900&family=Open+Sans:wght@700&family=Lilita+One:wght@400&display=swap');

html,
body,
#root {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

.letter-container {
  margin-top: -3px;
}

/* svg:hover {
  animation: float infinite;
  animation-duration: var(--animation-speed);
} */

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -0.5rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

html,
body,
#root {
  height: 100% !important;
}

.btn-ad-disabled {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/* Joyride */
.__floater .__floater__body .react-joyride__tooltip {
  border-radius: 0 !important;
  background-color: transparent !important;
  background-image: url('assets/bg-tutorial.png');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 160px;
}

.__floater .__floater__body .__floater__arrow svg polygon {
  fill: #cb7324 !important;
}

.__floater .__floater__body .react-joyride__tooltip h2 {
  font-weight: 700;
  font-size: 17px;
  color: #7f4620;
  padding: 0 20px;
  text-align: left;
  fontfamily: "'Encode Sans Semi Condensed', sans-serif";
  margin-top: -10px;
}

.__floater .__floater__body .react-joyride__tooltip ul li {
  font-weight: 600;
  font-size: 14px;
  color: #333;
  padding: 0 20px;
  fontfamily: "'Encode Sans Semi Condensed', sans-serif";
}

.__floater .__floater__body .react-joyride__tooltip ul li span.greens {
  color: #2a9e70;
}

.__floater .__floater__body .react-joyride__tooltip ul li span.yellows {
  color: #ad8a15;
}

.__floater .__floater__body .react-joyride__tooltip ul li span.grays {
  color: #929292;
}

.__floater .__floater__body .react-joyride__tooltip button {
  border-radius: 6px !important;
  padding: 6px 8px !important;
  font-size: 16px !important;
  background-color: transparent !important;
  font-weight: 600;
  color: #fff;
  margin-right: 15px;
  margin-top: -25px;
  font-family: 'Encode Sans Semi Condensed', sans-serif;
  width: 96px;
  height: 35px;
  background-image: url('assets/next-btn-tutorial.png');
  text-shadow: 1px 1px #54101b;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bgVideo video {
  object-fit: cover;
}
